<template>
    <div>
        <Wapheader />
        <div class="head-img">
            <img src="../../assets/images/dt/head-img.png" alt="">
        </div>
        <div class="dt-content">
            <img src="../../assets/images/dt/logo.png" alt="" style="width:35rem;">
            <div class="text_1">随时随地<span>发现光阳</span></div>
        </div>
        <div class="swiper mySwiper">
            <div class="swiper-wrapper" >
                <div v-for="(item,index) in list" :key="index" class="swiper-slide" >
                  <a :href="item.href">
                    <div><img :src="item.img" alt=""></div>
                    <div class="text_1" >
                        {{item.text1}}<br>
                        {{item.text2}}
                    </div>
                    <div class="text_2">{{item.sj}}</div>
                  </a>
                </div>
            </div>
        </div>
        <Wapfooter />
    </div>
</template>
<script>
export default {
    data() {
        return {
    list:[{
        href:"https://mp.weixin.qq.com/s/LZn-0RKzXDbeh4DEnFWk0w",
        img:require("../../assets/images/news-swiper1.png"),
        text1:"你好，光阳电动！",
        text2:"时代传奇 实在传承，[光阳电动]  元气登场",
        sj:"2021.5.29"
      },{
        href:"https://mp.weixin.qq.com/s/Wg03ieOH4koiVmmmyEUNng",
        img:require("../../assets/images/news-swiper2.png"),
        text1:"KYMCO光阳电动",
        text2:"豪华产品矩阵闪耀2021北京国际摩展",
        sj:"2021.5.29"
      },{
        href:"https://mp.weixin.qq.com/s/AXD1wj5wjARzTH6f-6i4Ig",
        img:require("../../assets/images/news-swiper3.png"),
        text1:"新速造!KYMCO光阳电动携全系产品",
        text2:"强新登陆海南EV大汇！",
        sj:"2021.5.29"
      },
      {
        href:"https://mp.weixin.qq.com/s/phrfCaxdfg2DqS4xdJ0mxw",
        img:require("../../assets/images/news-swiper4.png"),
        text1:"KYMCO光阳电动",
        text2:"豪华产品矩阵闪耀2021北京国际摩展",
        sj:"2021.5.29"
      },
      {
        href:"https://mp.weixin.qq.com/s/cEWPNX3HwnvwuHjz9mGcrw",
        img:require("../../assets/images/news-swiper5.png"),
        text1:"你说耀有光",
        text2:"S7线上发布会精彩全回顾",
        sj:"2021.5.29"
      },{
        href:"https://mp.weixin.qq.com/s/sIuTdiFnTfEBITpNI2NpGw",
        img:require("../../assets/images/news-swiper6.png"),
        text1:"",
        text2:"S7线上发布会精彩全回顾",
        sj:"2021.5.29"
      },
      {
        href:"https://mp.weixin.qq.com/s/oW_ooDGLWMcAsMpBkIUUOA",
        img:require("../../assets/images/news-swiper7.png"),
        text1:"燃爆夏日 热爱无限",
        text2:"百余潮酷玩家赛场竞技PK",
        sj:"2021.5.29"
      }
      ]
        }
    },
    mounted(){
        this.$nextTick(()=>{
            new Swiper('.mySwiper', {
                loop: false,
                slidesPerView:1.5,
                autoplay: true,//可选选项，自动滑动
                // centeredSlides: true,
                spaceBetween: 30,
            })
        })
    }
}
</script>
<style lang="less" scoped>
.text_2{
    font-size: 20px;
    font-family: 'Albbr';
    margin-top: 35px;
}
.text_1{
    margin-top: 25px;
    font-family: 'Albbr';
    font-size: 23px;
    white-space: nowrap;
}
.head-img {
    img {
        height: 390px;
    }
}
.mySwiper{
    padding-bottom: 240px;
    padding-left: 80px;
    img{
        height: 305px;
        width: 450px;
    }
}
.dt-content {
    padding: 90px 80px;

    .text_1 {
        margin-top: 25px;
        font-size: 32px;
        font-family: 'Albbr';
        line-height: 40px;

        span {
            margin-left: 10px;
        }
    }
}
</style>